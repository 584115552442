header{
  .top-header{
    background: #f6f6f6;
    .head{
      line-height: 44px;
      font-size: 16px;
      li{
        &.email{
          i{
            background: $primary-color;
            @include border-radius(50%);
            color: white;
            padding: 5px;
            font-size: 12px;
            margin-right: 5px;
          }
        }
        &.phone{
          i{
            background: $current-color;
            @include border-radius(50%);
            color: white;
            padding: 4px 6px;
            font-size: 16px;
            margin-right: 5px;
          }
        }
      }
      ul {
        margin:0;
        padding:0;
        text-align: right;
        float: none;
        ul{
          text-align: center;
        }
      }
    }
  }
  .header{
    padding: 15px 0;
    .contact{
      text-align: right;
      label{
        &.name{
          font-weight: normal;
          width: 80px;
        }
        &.email{
          width: 195px;
          text-align: left;
          font-weight:normal;
        }
      }
    }
    .logo{
      text-align: center;
    }
    .block{
      .title{
        font-size: 26px;
        font-weight:bold;
        font-style: italic;
        text-transform: uppercase;
        color: $primary-color;
      }
      .content{
        font-style: italic;
      }
    }
  }
  ul{
    li{
      padding-right:0;
    }
  }
}