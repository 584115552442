@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700|Source+Sans+Pro:400,600,700");
@import url(owl.carousel.css);
@import url(owl.theme.css);
@import url(owl.transitions.css);
@import url(fotorama.css);
.object-fit_fill {
  object-fit: fill;
}

.object-fit_contain {
  object-fit: contain;
}

.object-fit_cover {
  object-fit: cover;
}

.object-fit_none {
  object-fit: none;
}

.object-fit_scale-down {
  object-fit: scale-down;
}

html, body {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.checkbox label:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label:after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label:before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label:before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label:before {
  background-color: #428bca;
  border-color: #428bca;
}

.checkbox-primary input[type="checkbox"]:checked + label:after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label:before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked + label:after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label:before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked + label:after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label:before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked + label:after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label:before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label:after {
  color: #fff;
}

.radio {
  padding-left: 20px;
}

.radiolabel {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.radiolabel:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radiolabel:after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
}

.radio input[type="radio"]:focus + label:before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.radio input[type="radio"]:checked + label:after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label:before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label:after {
  background-color: #428bca;
}

.radio-primary input[type="radio"] + label:checked + label:before {
  border-color: #428bca;
}

.radio-primary input[type="radio"] + label:checked + label:after {
  background-color: #428bca;
}

.radio-danger input[type="radio"] + label:after {
  background-color: #d9534f;
}

.radio-danger input[type="radio"] + label:checked + label:before {
  border-color: #d9534f;
}

.radio-danger input[type="radio"] + label:checked + label:after {
  background-color: #d9534f;
}

.radio-info input[type="radio"] + label:after {
  background-color: #5bc0de;
}

.radio-info input[type="radio"] + label:checked + label:before {
  border-color: #5bc0de;
}

.radio-info input[type="radio"] + label:checked + label:after {
  background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label:after {
  background-color: #f0ad4e;
}

.radio-warning input[type="radio"] + label:checked + label:before {
  border-color: #f0ad4e;
}

.radio-warning input[type="radio"] + label:checked + label:after {
  background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label:after {
  background-color: #5cb85c;
}

.radio-success input[type="radio"] + label:checked + label:before {
  border-color: #5cb85c;
}

.radio-success input[type="radio"] + label:checked + label:after {
  background-color: #5cb85c;
}

header .top-header {
  background: #f6f6f6;
}

header .top-header .head {
  line-height: 44px;
  font-size: 16px;
}

header .top-header .head li.email i {
  background: #009aff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: white;
  padding: 5px;
  font-size: 12px;
  margin-right: 5px;
}

header .top-header .head li.phone i {
  background: #c91d1d;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: white;
  padding: 4px 6px;
  font-size: 16px;
  margin-right: 5px;
}

header .top-header .head ul {
  margin: 0;
  padding: 0;
  text-align: right;
  float: none;
}

header .top-header .head ul ul {
  text-align: center;
}

header .header {
  padding: 15px 0;
}

header .header .contact {
  text-align: right;
}

header .header .contact label.name {
  font-weight: normal;
  width: 80px;
}

header .header .contact label.email {
  width: 195px;
  text-align: left;
  font-weight: normal;
}

header .header .logo {
  text-align: center;
}

header .header .block .title {
  font-size: 26px;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  color: #009aff;
}

header .header .block .content {
  font-style: italic;
}

header ul li {
  padding-right: 0;
}

nav {
  background: #c91d1d;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul.nav li {
  display: inline-block;
  position: relative;
}

nav ul.nav li a {
  text-transform: capitalize;
  color: white;
  padding: 14px 16px;
}

nav ul.nav li a:hover, nav ul.nav li a:focus {
  background: #ef2a2a;
}

nav ul.nav li.active {
  background: #ef2a2a;
}

nav ul.nav li i {
  position: absolute;
  top: 17px;
  right: 5px;
  font-size: 16px;
  font-weight: bold;
}

nav ul.nav li.open > a {
  background: #ef2a2a;
}

nav ul.nav li.open > a:focus, nav ul.nav li.open > a:hover {
  background: #ef2a2a;
}

nav ul.nav li:hover ul {
  display: block;
}

nav ul.nav li ul {
  display: none;
  padding: 0;
  margin: 0;
}

nav ul.nav li ul.opened {
  display: block;
}

nav ul.nav li ul.sub-nav {
  background: white;
  border: 1px solid #E5E5E5;
  border-top: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  position: absolute;
  width: 300px;
  z-index: 100;
}

nav ul.nav li ul.sub-nav li {
  width: 100%;
  position: relative;
}

nav ul.nav li ul.sub-nav li + li {
  border-top: 1px solid #E5E5E5;
}

nav ul.nav li ul.sub-nav li a {
  color: #272727;
  display: block;
  padding: 8px 18px;
}

nav ul.nav li ul.sub-nav li a:hover {
  color: white;
}

nav ul.nav li ul.sub-nav li:hover ul {
  display: block;
}

nav ul.nav li ul.sub-nav li ul {
  display: none;
}

nav ul.nav li ul.sub-nav li ul.opened {
  display: block;
}

nav ul.nav li ul.sub-nav li ul.category {
  padding: 0;
  margin: 0;
  background: white;
  position: absolute;
  width: 300px;
  left: 100%;
  z-index: 110;
  top: 0;
  border: 1px solid #E5E5E5;
}

nav ul.nav li ul.sub-nav li ul.category li {
  padding: 0;
}

nav ul.nav li ul.sub-nav li ul.category li:after {
  content: ' ';
  position: absolute;
  border: 5px solid #cc2929;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 8px;
  top: calc(50% - 5px);
}

nav ul.nav li ul.sub-nav li ul.category li + li {
  border-top: 1px solid #E5E5E5;
}

nav ul.nav li ul.sub-nav li ul.category li:hover {
  background: #ef2a2a;
}

nav ul.nav li ul.sub-nav li ul.category li:hover a {
  color: white;
}

nav .bg-left {
  left: -40%;
}

nav .bg-right {
  right: -40%;
}

nav .bg-left,
nav .bg-right {
  position: absolute;
  height: 50px;
  width: calc(100% - 550px);
}

nav .bg-left.active,
nav .bg-right.active {
  background: #ef2a2a;
}

.navbar-toggle {
  border: 0;
  float: left;
}

.navbar-toggle i {
  font-size: 32px;
  color: white;
}

.mask {
  position: fixed;
  background: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
}

@media (max-width: 1024px) {
  header .header .contact {
    padding-left: 0 !important;
  }
  header .header .contact label.name {
    width: 60px !important;
  }
  ul.nav li a {
    padding: 14px 24px !important;
  }
}

@media (max-width: 767px) {
  nav ul.nav li {
    display: block;
  }
  nav ul.nav li ul {
    display: block;
  }
  nav ul.nav li ul.sub-nav {
    position: static;
    border: 0;
    background: transparent;
    width: auto;
  }
  nav ul.nav li ul.sub-nav li + li {
    border: 0;
  }
  nav ul.nav li ul.sub-nav li a {
    color: white;
  }
  nav ul.nav li ul.sub-nav li ul {
    display: block;
  }
  nav ul.nav li ul.sub-nav li ul.category {
    position: static;
    width: auto;
    background: transparent;
  }
}

a {
  outline: none;
  color: #666666;
}

a:hover {
  text-decoration: none;
}

#banner-slick .item-s {
  height: 495px;
}

#banner-slick .item-s .info-s span {
  font-size: 1.25714em;
}

#banner-slick .item-s .info-s hr {
  border-color: white;
  margin-bottom: 5px;
}

#quote {
  min-height: 1px;
}

#quote .quote-text {
  color: white;
  font-size: 24px;
  padding: 50px 0 40px 0;
}

#product-banners .col-sm-3:first-child h3:after {
  border-color: #c91d1d;
}

#main-content {
  background: url(../img/theme/homebg.jpg) no-repeat center top;
  -webkit-background-size: 100%;
  background-size: 100%;
  min-height: 1px;
}

#main-content .main-content {
  background-color: rgba(255, 255, 255, 0.6);
}

#main-content .main-content .section-header hr:after {
  border-color: #c91d1d;
}

#main-content .item-news {
  font-weight: normal;
  font-size: 14px;
}

#main-content .item-news img {
  max-height: 80px;
}

#menu-left ul li {
  height: auto;
  line-height: 40px;
  border-color: #E5E5E5;
}

#menu-left ul li a {
  display: block;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}

#menu-left ul li a .img-icon {
  height: auto;
  line-height: 44px;
  margin-left: 0;
}

#menu-left ul li a .img-icon img {
  margin-top: 0;
}

.owl-partners {
  position: relative;
}

.owl-partners .owl-item {
  padding: 5px;
}

.owl-partners .owl-item .item {
  padding: 10px;
  border: 1px solid #E5E5E5;
}

.owl-partners .owl-item .item img {
  max-height: 100px;
  max-width: 100%;
}

.owl-theme .owl-controls {
  position: absolute;
  top: -44px;
  right: 0;
}

.owl-theme .owl-controls .owl-buttons div {
  background: transparent;
  color: #363636;
  padding: 3px 0;
}

#service .service-top p {
  font-weight: normal;
}

#service .service-bot {
  background: rgba(245, 245, 245, 0.5);
  color: #272727;
}

#service .img:hover {
  background-color: #ef2a2a;
}

.footer-info i.fa {
  width: 24px;
  line-height: 24px;
  background: #009aff;
  color: white;
  text-align: center;
}

img.image-style {
  min-height: 250px;
  object-fit: contain;
}

.content-wrapper {
  padding: 40px 0;
}

.content-wrapper aside.aside-left .search {
  position: relative;
  margin-bottom: 5px;
}

.content-wrapper aside.aside-left .search .input {
  width: 100%;
  line-height: 26px;
  border: 1px solid #efefef;
  background: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding-right: 34px;
}

.content-wrapper aside.aside-left .search .btn-search {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  color: #979797;
  text-align: center;
  line-height: 20px;
  width: 34px;
  border: 0;
}

.content-wrapper aside.aside-left .block {
  background: #efefef;
}

.content-wrapper aside.aside-left .block .header {
  border-bottom: 1px solid #0087e0;
}

.content-wrapper aside.aside-left .block .header span {
  background: #0087e0;
  display: inline-block;
  padding: 12px 15px;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  color: #ffffff;
}

.content-wrapper aside.aside-left .block ul {
  margin: 0;
  padding: 0;
}

.content-wrapper aside.aside-left .block ul.category {
  list-style: none;
}

.content-wrapper aside.aside-left .block ul.category li {
  border-bottom: 1px solid #ffffff;
}

.content-wrapper aside.aside-left .block ul.category li:last-child {
  border-bottom: 0;
}

.content-wrapper aside.aside-left .block ul.category li a {
  padding: 10px 15px;
  color: #2a2a2a;
  display: block;
  text-transform: capitalize;
}

.content-wrapper aside.aside-left .block ul.category li a:hover {
  color: #0087e0;
}

.content-wrapper aside.aside-left .block ul.category li li {
  position: relative;
  margin-left: 10px;
}

.content-wrapper aside.aside-left .block ul.category li li:after {
  content: ' ';
  position: absolute;
  border: 5px solid #cc2929;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 15px;
  top: 17px;
}

.content-wrapper aside.aside-left .block ul.category li li a {
  padding-left: 30px;
}

.content-wrapper article h1.title {
  border-bottom: 1px solid #efefef;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 15px;
  padding: 8px 0;
}

.content-wrapper article h1.title span {
  border-bottom: 3px solid #cc2929;
  padding: 8px 0;
  margin-bottom: -1xp;
}

.content-wrapper article .block-item {
  margin-bottom: 30px;
}

.content-wrapper article .block-content h3 {
  text-transform: uppercase;
  color: #0087e0;
  font-weight: bold;
  font-size: 16px;
}

.content-wrapper article .block-content h3.title {
  border: 0;
  text-transform: capitalize;
  color: #2a2a2a;
  font-weight: bold;
  margin-bottom: 5px;
}

.content-wrapper article .block-content i.fa {
  color: #0087e0;
}

.content-wrapper article .block-content .image {
  text-align: center;
  border: 1px solid #e5e5e5;
}

.content-wrapper article .block-content .image img {
  max-width: 100%;
  min-height: 250px;
  object-fit: contain;
}

.content-wrapper article .block-content .date {
  color: #a8a8a8;
  font-size: 12px;
  font-weight: normal;
}

.content-wrapper article .block-content .description {
  font-weight: normal;
  margin-bottom: 10px;
}

.content-wrapper article .block-content .btn-red {
  background: #ef2a2a;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  margin-top: 10px;
  padding: 8px 15px;
  color: white;
  font-weight: normal;
}

.content-wrapper article .block-content .attribute {
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #e5e5e5;
}

.content-wrapper article .block-content .price {
  padding: 5px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.content-wrapper article .block-content .price span {
  color: #ef2a2a;
}

.content-wrapper article .block-content .content {
  padding: 15px 0;
}

.content-wrapper article .block-content .technical {
  background: #009aff;
  padding: 10px;
  margin-bottom: 15px;
  color: white;
  font-size: 16px;
}

.content-wrapper article .pagination li.active span {
  background: #009aff;
}

.content-wrapper article .pagination li span, .content-wrapper article .pagination li a {
  border: 1px solid #E5E5E5;
  margin: 0 3px;
}

.content-wrapper article .pagination li:first-child a, .content-wrapper article .pagination li:first-child span, .content-wrapper article .pagination li:last-child a, .content-wrapper article .pagination li:last-child span {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.content-wrapper article .pagination li i.fa {
  padding: 0;
}

.content-wrapper article .subject_product {
  display: none;
}

.content-wrapper article .subject_product.cond_evap, .content-wrapper article .subject_product.air_dryer {
  display: none;
}

.vdigit-0,
.vdigit-1,
.vdigit-2,
.vdigit-3,
.vdigit-4,
.vdigit-5,
.vdigit-6,
.vdigit-7,
.vdigit-8,
.vdigit-9 {
  width: 26px;
  height: 39px;
  background: url(../img/apple-middle.png) no-repeat top left;
  text-indent: -999em;
  display: inline-block;
}

.vdigit-0 {
  background-position: 0 0;
}

.vdigit-1 {
  background-position: -52px 0;
}

.vdigit-2 {
  background-position: -104px 0;
}

.vdigit-3 {
  background-position: -156px 0;
}

.vdigit-4 {
  background-position: -208px 0;
}

.vdigit-5 {
  background-position: -260px 0;
}

.vdigit-6 {
  background-position: -312px 0;
}

.vdigit-7 {
  background-position: -364px 0;
}

.vdigit-8 {
  background-position: -416px 0;
}

.vdigit-9 {
  background-position: -468px 0;
}

@media (min-width: 768px) {
  .block-content .col-sm-4:nth-child(3n+1), .block-content .col-sm-3:nth-child(4n+1) {
    clear: both;
  }
}

@media (max-width: 768px) {
  article {
    padding: 0 30px;
  }
  .block-content .col-xs-6:nth-child(2n+1) {
    clear: both;
  }
}
