nav {
    background: $current-color;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative;
        &.nav {
            li {
                display: inline-block;
                position: relative;
                a {
                    text-transform: capitalize;
                    color: white;
                    padding: 14px 16px;
                    &:hover, &:focus {
                        background: $private-color;
                    }
                }
                &.active {
                    background: $private-color;
                }
                i {
                    position: absolute;
                    top: 17px;
                    right: 5px;
                    font-size: 16px;
                    font-weight: bold;
                }
                &.open {
                    >a {
                        background: $private-color;
                        &:focus,
                        &:hover {
                            background: $private-color;
                        }
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
                ul {
                    display: none;
                    padding: 0;
                    margin: 0;
                    &.opened {
                        display: block;
                    }
                    &.sub-nav {
                        background: white;
                        border: 1px solid #E5E5E5;
                        border-top: 0;
                        border-radius: 0;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        position: absolute;
                        width: 300px;
                        z-index: 100;
                        li {
                            width: 100%;
                            position: relative;
                            +li {
                                border-top: 1px solid #E5E5E5;
                            }
                            a {
                                color: #272727;
                                display: block;
                                padding: 8px 18px;
                                &:hover {
                                    color: white;
                                }
                            }
                            &:hover {
                                ul {
                                    display: block;
                                }
                            }
                            ul {
                                display: none;
                                &.opened {
                                    display: block;
                                }
                                &.category {
                                    padding: 0;
                                    margin: 0;
                                    background: white;
                                    position: absolute;
                                    width: 300px;
                                    left: 100%;
                                    z-index: 110;
                                    top: 0;
                                    border: 1px solid #E5E5E5;
                                    li {
                                        padding: 0;
                                        &:after {
                                            content: ' ';
                                            position: absolute;
                                            border: 5px solid #cc2929;
                                            border-top: 5px solid transparent;
                                            border-bottom: 5px solid transparent;
                                            border-right: 5px solid transparent;
                                            left: 8px;
                                            top: calc(50% - 5px);
                                        }
                                        +li {
                                            border-top: 1px solid #E5E5E5;
                                        }
                                        &:hover {
                                            background: $private-color;
                                            a {
                                                color: white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bg-left {
        left: -40%;
    }
    .bg-right {
        right: -40%;
    }
    .bg-left,
    .bg-right {
        position: absolute;
        height: 50px;
        width: calc(100% - 550px);
        &.active {
            background: $private-color;
        }
    }
}

.navbar-toggle {
    border: 0;
    float: left;
    i {
        font-size: 32px;
        color: white;
    }
}
.mask {
    position: fixed;
    background: transparent;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    top: 0;
    left: 0;
}

@media (max-width: 1024px) {
    header {
        .header {
            .contact {
                padding-left: 0 !important;
                label {
                    &.name {
                        width: 60px !important;
                    }
                }
            }
        }
    }
    ul {
        &.nav {
            li {
                a {
                    padding: 14px 24px !important;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    nav {
        ul {
            &.nav {
                li {
                    display: block;
                    ul {
                        display: block;
                        &.sub-nav {
                            position: static;
                            border: 0;
                            background: transparent;
                            width: auto;
                            li {
                                + li {
                                    border: 0;
                                }
                                a {
                                    color: white;
                                }
                                ul {
                                    display: block;
                                    &.category {
                                        position: static;
                                        width: auto;
                                        background: transparent;
                                    }
                                }
                            }                            
                        }
                    }
                }
            }
        }
    }
}