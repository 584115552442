.checkbox {
  padding-left: 20px;
  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }
    &:after{
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #555555;
    }
  }
  input[type="checkbox"]{
    opacity: 0;
    z-index: 1;
    width:20px;
    height:20px;
    &:focus + label::before{
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
    &:checked + label::after{
      font-family: 'FontAwesome';
      content: "\f00c";
    }
    &:disabled + label{
      opacity: 0.65;
      &:before{
        background-color: #eeeeee;
        cursor: not-allowed;
      }
    }
  }
  &.checkbox-circle{
    label{
      &:before{
        border-radius: 50%;
      }
    }
  }
  &.checkbox-inline{
    margin-top: 0;
  }
}
.checkbox-primary{
  input[type="checkbox"]{
    &:checked+label{
      &:before{
        background-color: #428bca;
        border-color: #428bca;
      }
      &:after{
        color: #fff;
      }
    }
  }
}
.checkbox-danger{
  input[type="checkbox"]{
    &:checked+label{
      &:before{
        background-color: #d9534f;
        border-color: #d9534f;
      }
      &:after{
        color: #fff;
      }
    }
  }
}
.checkbox-info{
  input[type="checkbox"]{
    &:checked+label{
      &:before{
        background-color: #5bc0de;
        border-color: #5bc0de;
      }
      &:after{
        color: #fff;
      }
    }
  }
}
.checkbox-warning{
  input[type="checkbox"]{
    &:checked+label{
      &:before{
        background-color: #f0ad4e;
        border-color: #f0ad4e;
      }
      &:after{
        color: #fff;
      }
    }
  }
}
.checkbox-success{
  input[type="checkbox"]{
    &:checked+label{
      &:before{
        background-color: #5cb85c;
        border-color: #5cb85c;
      }
      &:after{
        color: #fff;
      }
    }
  }
}
.radio {
  padding-left: 20px;
  &label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }
    &:after{
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #555555;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }
  input[type="radio"]{
    opacity: 0;
    z-index: 1;
    &:focus+label{
      &:before{
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }
    &:checked+label{
      &:after{
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
      }
    }
    &:disabled+label{
      opacity: 0.65;
      &:before{
        cursor: not-allowed;
      }
    }
  }
  &.radio-inline{
    margin-top: 0;
  }
}
.radio-primary{
  input[type="radio"] + label{
    &:after{
      background-color: #428bca;
    }
    &:checked+label{
      &:before{
        border-color: #428bca;
      }
      &:after{
        background-color: #428bca;
      }
    }
  }
}
.radio-danger{
  input[type="radio"] + label{
    &:after{
      background-color: #d9534f;
    }
    &:checked+label{
      &:before{
        border-color: #d9534f;
      }
      &:after{
        background-color: #d9534f;
      }
    }
  }
}
.radio-info{
  input[type="radio"] + label{
    &:after{
      background-color: #5bc0de;
    }
    &:checked+label{
      &:before{
        border-color: #5bc0de;
      }
      &:after{
        background-color: #5bc0de;
      }
    }
  }
}
.radio-warning{
  input[type="radio"] + label{
    &:after{
      background-color: #f0ad4e;
    }
    &:checked+label{
      &:before{
        border-color: #f0ad4e;
      }
      &:after{
        background-color: #f0ad4e;
      }
    }
  }
}
.radio-success{
  input[type="radio"] + label{
    &:after{
      background-color: #5cb85c;
    }
    &:checked+label{
      &:before{
        border-color: #5cb85c;
      }
      &:after{
        background-color: #5cb85c;
      }
    }
  }
}