.object-fit_fill { object-fit: fill }
.object-fit_contain { object-fit: contain }
.object-fit_cover { object-fit: cover }
.object-fit_none { object-fit: none }
.object-fit_scale-down { object-fit: scale-down }
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Source+Sans+Pro:400,600,700');
html,body { font-size: 16px; font-family: 'Source Sans Pro', sans-serif; font-weight: 400; }
@import "owl.carousel.css";
@import "owl.theme.css";
@import "owl.transitions.css";
@import "fotorama.css";
@import "variable";
@import "checkbox";
@import "header";
@import "nav";
a{
  outline:none;
  &:hover{
    text-decoration: none;
  }
  color: #666666;
}
#banner-slick{
  .item-s{
    height: 495px;
    .info-s{
      span{
        font-size: 1.25714em;
      }
      hr{
        border-color: white;
        margin-bottom: 5px;
      }
    }
  }
}
#quote{
  min-height:1px;
  .quote-text {
    color: white;
    font-size: 24px;
    padding: 50px 0 40px 0;
  }
}
#product-banners {
  .col-sm-3:first-child{
    h3{
      &:after{
        border-color: $current-color;
      }
    }
  }
}
#main-content {
  background: url(../img/theme/homebg.jpg) no-repeat center top;
  -webkit-background-size:100%;
  background-size:100%;
  min-height: 1px;
  .main-content{
    background-color: rgba(255, 255, 255, 0.6);
    .section-header{
      hr{
        &:after{
          border-color: $current-color;
        }
      }
    }
  }
  .item-news{
    font-weight: normal;
    font-size: 14px;
    img{
      max-height: 80px;
    }
  }
}
#menu-left{
  ul{
    li{
      height:auto;
      line-height: 40px;
      border-color: #E5E5E5;
      a{
        display: block;
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
        .img-icon{
          height: auto;
          line-height: 44px;
          margin-left: 0;
          img{
            margin-top: 0;
          }
        }
      }
    }
  }
}
.owl-partners{
  position: relative;
  .owl-item{
    padding: 5px;
    .item{
      padding: 10px;
      border:1px solid #E5E5E5;
      img{
        max-height: 100px;
        max-width:100%;
      }
    }
  }
}
.owl-theme{
  .owl-controls{
    position: absolute;
    top: -44px;
    right:0;
    .owl-buttons{
      div{
        background: transparent;
        color: #363636;
        padding:3px 0;
      }
    }
  }
}
#service{
  .service-top{
    p{
      font-weight: normal;
    }
  }
  .service-bot{
    background: rgba(245, 245, 245, 0.5);
    color: #272727;
  }
  .img{
    &:hover{
      background-color: $private-color;
    }
  }
}

.footer-info {
  i.fa{
    width: 24px;
    line-height: 24px;
    background: $primary-color;
    color: white;
    text-align: center;
  }
}
img{
  &.image-style {
    min-height: 250px;
    object-fit: contain;
  }
}
.content-wrapper {
  padding: 40px 0;
  aside{
    &.aside-left {
      .search{
        position: relative;
        margin-bottom: 5px;
        .input{
          width:100%;
          line-height:26px;
          border:1px solid #efefef;
          background: #ffffff;
          -webkit-border-radius:0;
          -moz-border-radius:0;
          border-radius:0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          padding-right: 34px;
        }
        .btn-search{
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          color: #979797;
          text-align: center;
          line-height: 20px;
          width: 34px;
          border: 0;
        }
      }
      .block{
        background: #efefef;
        .header{
          border-bottom:1px solid #0087e0;
          span{
            background: #0087e0;
            display: inline-block;
            padding: 12px 15px;
            font-weight:bold;
            font-size: 15px;
            text-transform: uppercase;
            color: #ffffff;
          }
        }
        ul{
          margin:0;
          padding:0;
          &.category{
            list-style: none;
            li{
              border-bottom:1px solid #ffffff;
              &:last-child{
                border-bottom:0;
              }
              a{
                padding: 10px 15px;
                color: #2a2a2a;
                display: block;
                text-transform: capitalize;
                &:hover{
                  color: #0087e0;
                }
              }
              li{
                position: relative;
                margin-left: 10px;
                &:after{
                  content: ' ';
                  position: absolute;
                  border: 5px solid #cc2929;
                  border-top: 5px solid transparent;
                  border-bottom: 5px solid transparent;
                  border-right: 5px solid transparent;
                  left: 15px;
                  top: 17px;
                }
                a{
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  article{
    h1{
      &.title {
        border-bottom: 1px solid #efefef;
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 15px;
        padding: 8px 0;
        span {
          border-bottom: 3px solid #cc2929;
          padding: 8px 0;
          margin-bottom: -1xp;
        }
      }
    }
    .block-item{
      margin-bottom: 30px;
    }
    .block-content{
      h3{
        text-transform: uppercase;
        color: #0087e0;
        font-weight:bold;
        font-size: 16px;
        &.title{
          border: 0;
          text-transform: capitalize;
          color: #2a2a2a;
          font-weight:bold;
          margin-bottom: 5px;
        }
      }
      i{
        &.fa{
          color: #0087e0;
        }
      }
      .image{
        img{
          max-width:100%;
          min-height: 250px;
          object-fit: contain;
        }
        text-align: center;
        border: 1px solid #e5e5e5;
      }
      .date{
        color: #a8a8a8;
        font-size: 12px;
        font-weight:normal;
      }
      .description{
        font-weight: normal;
        margin-bottom: 10px;
      }
      .btn-red{
        background: $private-color;
        -webkit-border-radius:0;
        -moz-border-radius:0;
        border-radius:0;
        border:0;
        margin-top: 10px;
        padding: 8px 15px;
        color: white;
        font-weight:normal;
      }
      .attribute{
        margin-bottom: 10px;
        padding: 5px 0;
        border-bottom: 1px solid #e5e5e5;
      }
      .price {
        padding: 5px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #e5e5e5;
        span{
          color: $private-color;
        }
      }
      .content{
        padding: 15px 0;
      }
      .technical{
        background: $primary-color;
        padding: 10px;
        margin-bottom: 15px;
        color: white;
        font-size: 16px;
      }
    }
    .pagination {
      li{
        &.active{
          span{
            background: $primary-color;
          }
        }
        span, a {
          border: 1px solid #E5E5E5;
          margin: 0 3px;
        }
        &:first-child, &:last-child{
          a, span{
            -webkit-border-radius:0;
            -moz-border-radius:0;
            border-radius:0;
          }
        }
        i{
          &.fa{
            padding: 0;
          }
        }
      }
    }
    .subject_product{
      display: none;
      &.cond_evap, &.air_dryer{
        display: none;
      }
    }
  }
}
.vdigit-0,
.vdigit-1,
.vdigit-2,
.vdigit-3,
.vdigit-4,
.vdigit-5,
.vdigit-6,
.vdigit-7,
.vdigit-8,
.vdigit-9{width:26px;height:39px;background:url(../img/apple-middle.png) no-repeat top left;text-indent:-999em;display:inline-block;}
.vdigit-0{background-position:0 0;}
.vdigit-1{background-position:-52px 0;}
.vdigit-2{background-position:-104px 0;}
.vdigit-3{background-position:-156px 0;}
.vdigit-4{background-position:-208px 0;}
.vdigit-5{background-position:-260px 0;}
.vdigit-6{background-position:-312px 0;}
.vdigit-7{background-position:-364px 0;}
.vdigit-8{background-position:-416px 0;}
.vdigit-9{background-position:-468px 0;}
@media (min-width: 768px) {
  .block-content{
    .col-sm-4:nth-child(3n+1), .col-sm-3:nth-child(4n+1){
      clear: both;
    }
  }
}
@media (max-width:768px) {
  article{
    padding:0 30px;
  }
  .block-content{
    .col-xs-6:nth-child(2n+1){
      clear: both;
    }
  }
}
